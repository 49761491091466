/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import "../node_modules/@angular/material/prebuilt-themes/pink-bluegrey.css";

@font-face {
    font-family: 'goldman';
    src: url('./assets/fonts/Goldman/Goldman-Regular.ttf');
}

body {
    font-family: 'goldman';
}

table {
    width: 100%;
}

strong {
    color: white;
}

td {
    color: #A6B0B9;
}

.container {
    padding-left: 0px;
    padding-right: 0px;
    box-shadow: 0px 0px 40px 0px #6a6a6a;
}